import { useLocation, Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../assets/arrow-right.svg';
import { Fragment } from 'react';

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((pathname) => pathname);

  const pathNameItems = pathnames.slice(0, 2);

  const formatPathname = (pathname) => {
    return pathname
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <nav>
      <ol className="flex items-center">
        {pathNameItems.map((pathname, index) => {
          const url = `/${pathNameItems.slice(0, index + 1).join('/')}`;
          const isLast = index === pathNameItems.length - 1;

          return (
            <Fragment key={url}>
              <li className={`${isLast ? 'text-ebonyclay' : 'text-uberblue'}`}>
                <Link to={url} className="capitalize">
                  {formatPathname(pathname)}
                </Link>
              </li>
              {!isLast && <ArrowRight className="mx-2" />}
            </Fragment>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
