import { useState, useRef, useEffect } from 'react';
import format from 'date-fns/format';
import { useNavigate, useLocation } from 'react-router-dom';
import { useModal } from '../context/ModalContext';
import { useMediaQuery } from 'react-responsive';

import { useAuth } from '../context/Auth';
import { useAppQuery } from '../hooks/useAppQuery';
import IdleTimer from '../utils/timer';
import ClientHeader from './ClientHeader';
import Breadcrumb from './Breadcrumb';
import useClickOutside from '../hooks/useClickOutside';

import { Hamburger } from '../utils/assets/Hamburger';
import { linkItems } from '../utils/linkItems';
import { Logout } from '../utils/assets/Logout';

const Layout = ({ children }) => {
  const navigate = useNavigate();

  const { auth, setUserData, logout } = useAuth();
  useAppQuery(
    `user-data_${auth?.user?.id}`,
    {
      url: `v1/users/${auth?.user?.id}`,
    },
    {
      onSuccess: (data) => {
        setUserData(data?.data);
      },
    }
  );

  const { handleModal } = useModal();
  const { pathname } = useLocation();
  const navRef = useRef();
  const [hideSidebar, setHideSidebar] = useState(true);

  const sidebarRef = useClickOutside(
    () => !hideSidebar && setHideSidebar(true)
  );

  const handleLinkClick = (to, component) => {
    if (to === '#') {
      handleModal(component);
    } else {
      navigate(to);
    }
    setHideSidebar(true);
  };

  const isTabletOnly = useMediaQuery({
    minWidth: 1024,
    maxWidth: 1279,
  });

  const [hoveredLinkIndex, setHoveredLinkIndex] = useState(null);

  const handleIconHover = (index) => {
    setHoveredLinkIndex(index);
  };

  const handleIconLeave = () => {
    setHoveredLinkIndex(null);
  };

  useEffect(() => {
    const idleTimer = new IdleTimer(
      process.env.REACT_APP_TIMEOUT ?? 10 * 60,
      () => logout()
    );

    return () => {
      idleTimer.cleanUp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="bg-white flex font-lato h-screen overflow-auto text-ebonyclay">
      <div
        ref={sidebarRef}
        className={`max-w-240 w-7/12 lg:w-28 xl:w-4/12 border border-ebonyclay bg-ebonyclay z-[60] lg:z-auto fixed lg:block lg:sticky top-0 left-0 bottom-0 self-start h-full min-w-80p transform lg:translate-x-0 transition duration-200 ease-in-out ${
          hideSidebar ? '-translate-x-full hidden' : ''
        }`}
      >
        <div className="pt-30 text-uberblue">
          <div className="ml-2 sm:ml-12 w-full">
            <ClientHeader
              nav
              user={auth?.user}
              onClick={() => navigate('/dashboard/profile')}
              fullname={`${auth?.user?.fname ?? ''} ${auth?.user?.lname ?? ''}`}
            />
          </div>

          <nav className="w-full mx-auto h-[400px] sm:h-auto hide-scroll-bar overflow-y-auto mt-104">
            {linkItems.map((link, index) => {
              return (
                <div
                  key={index}
                  className={`flex items-center pl-7 cursor-pointer lg:pl-6 xl:pl-10 mb-6 text-base w-full hover:text-jungleGreen ${
                    pathname.startsWith(link.to)
                      ? 'text-jungleGreen border-l-2 border-jungleGreen'
                      : ''
                  } ${index === linkItems.length - 1 ? 'mt-10' : 'mt-3'}`}
                  onClick={() => handleLinkClick(link.to, link.component)}
                  onMouseEnter={
                    isTabletOnly ? () => handleIconHover(index) : null
                  }
                  onMouseLeave={isTabletOnly ? handleIconLeave : null}
                >
                  {isTabletOnly && hoveredLinkIndex === index ? (
                    <p className="block text-jungleGreen text-sm mx-auto text-center">
                      {link.label}
                    </p>
                  ) : (
                    <span className="w-5 h-5 mr-4 lg:mx-auto xl:ml-0 xl:mr-4">
                      <link.icon className="w-5 h-5 mr-4 lg:mx-auto xl:ml-0 xl:mr-4" />
                    </span>
                  )}

                  <span className="inline-block text-sm sm:text-base lg:hidden xl:inline-block">
                    {link.label}
                  </span>
                </div>
              );
            })}
            <div
              className="cursor-pointer flex items-center pl-7 lg:pl-14 xl:pl-10 gap-5 my-10 md:mt-[4rem] text-base w-full hover:text-jungleGreen"
              onClick={() => logout()}
            >
              <Logout />
              <span className="inline-block lg:hidden xl:inline-block">
                Logout
              </span>
            </div>
          </nav>
        </div>
      </div>
      <div className="flex-1 overflow-x-hidden relative">
        <div className="lg:py-4 border-mischka fixed w-full lg:w-93% xl:w-full xl:sticky border-b top-0 bg-ebonyclay lg:bg-white lg:border-0.5 lg:border-loblolly z-3">
          <div className="flex justify-between lg:hidden px-4 h-56p">
            <div className="flex h-full">
              <button
                ref={navRef}
                onClick={() => setTimeout(() => setHideSidebar(!hideSidebar))}
                className="mt-2 mb-2 focus:outline-none"
              >
                <Hamburger className="w-6 h-6 text-white" />
              </button>
            </div>
            <div className="py-2 ">
              <ClientHeader
                user={auth?.user}
                onClick={() => navigate('/dashboard/profile')}
                fullname={`${auth?.user?.fname ?? ''} ${
                  auth?.user?.lname ?? ''
                }`}
              />
            </div>
          </div>
          <div className="px-4 hidden lg:flex justify-between lg:ml-4 lg:mr-8">
            <Breadcrumb />
            <div className="flex ">
              <p className="mr-2 font-normal">
                {format(new Date(), 'd MMM, yyyy')}
              </p>
            </div>
          </div>
        </div>
        <div>{children}</div>
      </div>
    </section>
  );
};

export default Layout;
