import { twMerge } from 'tailwind-merge';
import kudyLogo from '../../assets/images/kudyLogo.png';
const KudyLogoText = ({ className, sarl, ltd, ...props }) => {
  let text;
  if (sarl) {
    text = 'S.À.R.L';
  }
  if (ltd) {
    text = 'Limited';
  }
  return (
    <div
      className={twMerge(
        'flex h-[54px] w-[144px] items-center gap-1 text-ebonyclay',
        className
      )}
      {...props}
    >
      <img src={kudyLogo} width={49} height={54} alt="logo" />
      <span className="mt-[3px] font-butler text-sm font-light uppercase leading-[normal]">
        Kudy Financials {text}
      </span>
    </div>
  );
};

export default KudyLogoText;
