import { Ascending } from '../../utils/assets/Ascending';
import { Descending } from '../../utils/assets/Descending';

const TableHeader = ({ columns, onSort }) => {
  const hasActionColumn = columns.some((column) => column.label === 'Action');

  return (
    <>
      {columns.map((column, index) => (
        <div
          className={`flex items-center font-bold p-4 border-b border-gray-300 ${
            hasActionColumn && column.label === 'Action'
              ? 'col-start-[-2] col-end-[-1]'
              : ''
          }`}
          key={index}
        >
          <span
            className={
              hasActionColumn && column.label === 'Action' ? 'ml-auto' : ''
            }
          >
            {column.label}
          </span>
          {column.showSortIcon && (
            <span className="ml-3 flex flex-col">
              <span
                className="mb-1 cursor-pointer"
                onClick={() => onSort && onSort(column.field, 'asc')}
              >
                <Ascending />
              </span>
              <span
                className="mb-1 cursor-pointer"
                onClick={() => onSort && onSort(column.field, 'desc')}
              >
                <Descending />
              </span>
            </span>
          )}
        </div>
      ))}
    </>
  );
};

export default TableHeader;
