import { stringToAvatar } from '../utils/stringToAvatar.js';

/**
 * @returns Avator Component
 */
const Avatar = ({ user, onClick }) => {
  return (
    <img
      onClick={onClick}
      className="w-10 h-10 rounded-full inline-block"
      src={stringToAvatar(user)}
      alt="Profile Avatar"
    />
  );
};

export default Avatar;
