import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AuthProvider } from './context/Auth';
import Routes from './routes/Routes';

import Loader from './components/Loader';
import { KreditProvider } from './context/Kredit';
import { PromptProvider } from './context/PromptContext';
import { Buffer } from 'buffer';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 3600000,
      retry: false,
    },
  },
});

window.Buffer = window.Buffer || Buffer;

/**
 * App component
 * @returns jsx
 */
function App() {
  return (
    <AuthProvider>
      <KreditProvider>
        <PromptProvider>
          <QueryClientProvider client={queryClient}>
            <Suspense fallback={<Loader />}>
              <main className="font-lato">
                <Routes />
              </main>
            </Suspense>
          </QueryClientProvider>
        </PromptProvider>
      </KreditProvider>
    </AuthProvider>
  );
}

export default App;
