import { useState } from 'react';
import { useFormik } from 'formik';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';

import ModalHeader from './ModalHeader';
import { UnderlinedButton as Button } from './Button';
import Plus from './Plus';
import { Toast } from '../helpers/toastify.helpers';

import { validationSchema } from '../pages/AdminProfile/UploadDocs';
import { useAppQuery, useAppMutation } from '../hooks/useAppQuery';
import { useAuth } from '../context/Auth';
import { formatDateForFundCycleList } from '../utils/date';

const Box = styled.div.attrs({
  className: 'flex justify-between',
})`
  flex-direction: column;
  .expiry-detail {
    order: 2;
    margin-top: 2em;
    margin-left: auto;
  }

  @media (min-width: 1439px) {
    flex-direction: row;
    .expiry-detail {
      order: 0;
      margin: 0px;
    }
  }
`;

/**
 * @returns MonthlyReport component
 */
const MonthlyReport = () => {
  const { auth } = useAuth();
  const [fileName, setFileName] = useState('');

  const [values, setValues] = useState({
    file: '',
  });

  const { data } = useAppQuery(`user-documents_${auth?.user?.id}`, {
    url: `v1/users/${auth?.user?.id}/documents`,
  });

  const queryClient = useQueryClient();
  const { mutate, isPending } = useAppMutation(
    {
      url: `v1/users/${auth?.user?.id}/documents/upload`,
      data: { file: values.file, type: 'monthly_report' },
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [`user-documents_${auth?.user?.id}`],
          refetchType: 'all',
        });

        Toast({
          message: 'Monthly Report Updated',
          type: 'success',
        });
      },
      onSettled: (_, error) => {
        setValues({
          file: '',
        });
        if (error) {
          Toast({
            message:
              error?.response?.data?.message ??
              error?.message ??
              'Process Failed, Please try again',
            type: 'error',
          });
        }
      },
    }
  );

  const formik = useFormik({
    initialValues: values,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async () => {
      await mutate();
      setFileName('');
    },
  });

  /**
   * handleChange
   *  @param {event} event object
   */
  const handleChange = (event) => {
    const { files } = event.target;
    const file = files[0];
    setFileName(file?.name);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (event) {
      const { result } = event.target;
      setValues((prevState) => ({
        ...prevState,
        file: result,
      }));
    };
  };

  return (
    <section className="w-11/12 mx-auto">
      <ModalHeader title="Monthly Report" />
      <h2 className="uppercase mb-20">Select Document to upload</h2>
      {data?.data
        ?.filter((item) => item.type === 'monthly_report')
        ?.map((doc) => (
          <a
            key={doc.id}
            className="text-14 text-kudyblue block
          "
            href={doc ? doc?.document : '#'}
            target="_blank"
            rel="noreferrer"
          >
            View Document ({formatDateForFundCycleList(doc.created_at)})
          </a>
        )) ?? <small className="text-12 italic">No documents yet.</small>}

      <form className="mt-40p" onSubmit={formik.handleSubmit}>
        <input
          name="file"
          type="file"
          id="monthly_report"
          accept="image/*,application/pdf"
          className="invisible"
          onChange={handleChange}
        />
        <Box>
          <p className="text-14 self-center ml-auto expiry-detail">
            Report Expires every{' '}
            <span className="text-kudyblue">25th day of the month</span>.
          </p>
          <div className="flex justify-end">
            <Button
              type="submit"
              styleClasses="block cursor-pointer mr-45"
              loading={isPending}
              disabled={!formik.isValid}
            >
              Update
            </Button>
            <label
              htmlFor="monthly_report"
              className="block cursor-pointer border-b border-ebonyclay"
            >
              Browse Documents <Plus />
            </label>
          </div>
        </Box>

        <small className="block text-right opacity-50 mt-2.5">{fileName}</small>
      </form>
    </section>
  );
};

export default MonthlyReport;
