import { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import format from 'date-fns/format';
import DatePicker from 'react-datepicker';
import Clear from '../utils/assets/Clear';
import 'react-datepicker/dist/react-datepicker.css';
import CurrencyInput from 'react-currency-input-field';
import getSymbolFromCurrency from 'currency-symbol-map';

import { customStyles } from '../helpers/selectHelpers.js';

import SearchIcon from './SearchIcon';

import { Toast } from '../helpers/toastify.helpers';
import Select from 'react-select';
import ArrowDown from '../utils/assets/ArrowDown';
import { ArrowLeft } from '../utils/assets/ArrowLeft';
import ArrowRight from '../utils/assets/ArrowRight';
import Info from '../utils/assets/Info';
import Calendar from '../utils/assets/Calendar';
import Search from '../utils/assets/Search.jsx';

export const Input = styled.input.attrs({
  className:
    'w-full border-b border-solid text-black border-ebonyclay bg-transparent focus:outline-none placeholder-placeholder1',
})`
  &:focus + .list-none {
    display: block;
  }

  ${({ shorterInput }) =>
    shorterInput &&
    css`
      margin-bottom: 10px;
      width: 50%;
      @media (min-width: 768px) {
        width: 40%;
      }
    `}

  ${({ auth }) =>
    auth &&
    css`
      padding: 0px;
      border-bottom: 1px solid #fff;
      color: #fff;
      &::placeholder {
        color: #fff;
      }
    `}

    ${({ disabled }) =>
    disabled &&
    css`
      cursor: pointer;
    `}
`;

/**
 * BaseInput Component
 * @returns jsx
 */
export const BaseInput = ({
  label,
  type,
  onChange,
  name,
  value,
  onBlur = () => null,
  styleClasses = '',
  placeholder = '',
  id = '',
  disabled = false,
  autoComplete,
  shorterInput,
  auth,
  errorMsg,
  touched,
  ...rest
}) => {
  useEffect(() => {
    if (touched) {
      return (
        errorMsg &&
        touched[name] &&
        Toast({
          message: errorMsg,
          type: 'error',
        })
      );
    }
  }, [errorMsg, touched, name]);

  return (
    <>
      <label
        htmlFor={id}
        className={`block font-lato mb-2.5 capitalize ${
          auth ? 'text-left text-white' : ''
        }`}
      >
        {label}
      </label>
      <Input
        className={styleClasses}
        type={type}
        onChange={onChange}
        name={name}
        value={value}
        onBlur={onBlur}
        placeholder={placeholder}
        id={id}
        disabled={disabled}
        autoComplete={autoComplete}
        shorterInput={shorterInput}
        auth={auth}
        {...rest}
      />
    </>
  );
};

/**
 * Search Input component
 * @param {Object} props props
 * @returns jsx
 */
export const SearchInput = ({
  lists = [],
  selectListItem,
  query,
  setQuery,
}) => {
  /**
   * RestrictedRoutes component
   * @param {array} list list
   * @returns {string} height
   */
  const getHeight = (list) => {
    let height;
    if (list.length <= 0) {
      height = 'h-0';
    } else if (list.length === 1) {
      height = 'h-110p';
    } else if (list.length === 2) {
      height = 'h-220p';
    } else {
      height = 'h-330p';
    }
    return height;
  };

  return (
    <div className="relative">
      <Input
        type="text"
        autoComplete="off"
        name="query"
        id="query"
        placeholder="Search for a Fund..."
        onChange={(e) => setQuery(e.target.value)}
        value={query}
        required
      />
      <ul
        className={`bg-pampas z-20 absolute hidden ${getHeight(
          lists
        )} overflow-auto list-none top-25 bottom-0 inset-x-0 shadow-darkshadow`}
      >
        {lists.map((list) => {
          return (
            <li
              className="pt-20 pb-15 mx-15p mb-2.5 border-b border-solid border-black border-opacity-25 cursor-pointer"
              key={list.id}
              value={list.id}
              onMouseDown={() => selectListItem(list)}
            >
              <span className="font-lato text-14 block">{list.title}</span>
              <span className="pt-15 font-lato text-12 opacity-50 block">
                {list.description}
              </span>
            </li>
          );
        })}
      </ul>
      <SearchIcon />
    </div>
  );
};

/**
 * MoneyInput component for formatted amount inputs
 * @param {Object} props props
 * @returns jsx
 */
export const MoneyInput = ({
  onValueChange,
  value,
  onBlur,
  name,
  styleClasses = '',
  id,
  currency,
  errorMsg,
  errored,
  disabled = false,
}) => {
  useEffect(() => {
    if (errored) {
      Toast({
        message: errorMsg,
        type: 'error',
      });
    }
  }, [errorMsg, errored]);

  return (
    <CurrencyInput
      allowNegativeValue={false}
      className={`block leading-19 w-full md:w-3/6 borderx border-solid text-black border-ebonyclay bg-transparent focus:outline-none placeholder-placeholder1 ${styleClasses}`}
      id={id}
      name={name}
      decimalsLimit={2}
      onValueChange={onValueChange}
      value={value}
      onBlur={onBlur}
      prefix={getSymbolFromCurrency(currency)}
      defaultValue={0}
      disabled={disabled}
    />
  );
};
const DatePickerContainer = styled.div`
  .react-datepicker__header {
    background-color: #ffffff;
    border-bottom: none;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker {
    border: 0.5px solid green;
    top: 1.5rem;
    font-family: 'Mulish', sans-serif;
  }
  .react-datepicker__day--selected {
    background-color: transparent;
    color: #0c0c0d;
    border: 0.5px solid green;
  }
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: #5c5a5a;
  }
  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 0px;
  }
`;

export const DateInput = ({
  label,
  styleClasses = '',
  iconStyles = '',
  minDate = false,
  ...rest
}) => {
  return (
    <DatePickerContainer className="relative mb-2">
      {label && (
        <label
          htmlFor={rest.name}
          className="mb-1 block font-normal capitalize text-blueWood"
        >
          {label}
        </label>
      )}
      <div className="">
        <div className="rounded border focus-within:border-jungleGreen border-loblolly py-3">
          <DatePicker
            className={`focus:outline-none placeholder-gray-400  w-full focus:border-jungleGreen disabled:bg-gray-200 disabled:cursor-not-allowed px-1.5 rounded-sm cursor-pointer relative ${
              rest?.errored
                ? 'border-0.5 border-valencia focus:border-valencia'
                : ''
            } ${styleClasses}`}
            minDate={minDate}
            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
              <div className="flex justify-between px-2 text-sm">
                <ArrowLeft onClick={decreaseMonth} styles="cursor-pointer" />
                <p className="font-bold ">{format(date, 'MMMM yyyy')}</p>
                <ArrowRight
                  onClick={increaseMonth}
                  className="cursor-pointer"
                />
              </div>
            )}
            {...rest}
          />
        </div>{' '}
        {rest?.errored ? (
          <Info className="absolute top-40 right-0" />
        ) : (
          <Calendar styles={iconStyles} />
        )}
      </div>
      <small className={'absolute -bottom-24 left-0 text-valencia truncate'}>
        {rest?.errorMessage}
      </small>
    </DatePickerContainer>
  );
};

export const PrefilledCurrencyInput = ({
  buttonText,
  prefillValue,
  onPrefill,
  currency,
  placeholder,
  onValueChange,
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleButtonClick = () => {
    setInputValue(prefillValue.toString());
    onPrefill(prefillValue);
  };

  const handleInputChange = (value) => {
    setInputValue(value);
    onValueChange(value);
  };

  return (
    <div className="flex items-center p-2 rounded-sm border border-loblolly justify-between">
      <CurrencyInput
        className="p-2 w-36 focus:outline-none"
        placeholder={placeholder}
        allowNegativeValue={false}
        prefix={getSymbolFromCurrency(currency)}
        value={inputValue}
        onValueChange={handleInputChange}
      />
      <button
        className="bg-jungleGreen focus:outline-none text-white text-sm md:text-base font-bold py-2 px-2 md:px-6 rounded-sm"
        onClick={handleButtonClick}
      >
        {buttonText}
      </button>
    </div>
  );
};

export const NewSearchInput = ({
  placeholder,
  handleChange,
  value,
  disabled,
  onFocus,
  styles,
}) => {
  const handleInputChange = (e) => {
    const newValue = e.target.value;
    const capitalizedValue = newValue
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    handleChange(capitalizedValue);
  };

  return (
    <div
      className={`border-0.5 flex items-center p-2 rounded-[3px] border-loblolly focus-within:border-jungleGreen h-[45px] relative ${styles}`}
    >
      <input
        className="nosubmit placeholder:text-14 focus:outline-none w-full"
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={handleInputChange}
        disabled={disabled}
        onFocus={onFocus}
      />
      {value && !disabled && (
        <span
          className="text-ebonyclay text-12 py-1 cursor-pointer"
          onClick={() => handleChange('')}
        >
          <Clear color="#989EA9" />
        </span>
      )}
    </div>
  );
};

export const SearchDropdown = ({
  placeholder,
  handleChange,
  value,
  disabled,
  onFocus,
  styles,
  data,
  handleSelection,
  label,
}) => {
  const [optionList, setOptionList] = useState(false);

  const handleOptionSelection = (item) => {
    handleSelection(item);
    setOptionList(false);
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    const capitalizedValue = newValue
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');

    handleChange(capitalizedValue);
    setOptionList(true);
  };

  return (
    <div className={styles}>
      <div
        className={`border-0.5 flex items-center justify-between p-3 sm:p-4 rounded-[3px] border-loblolly focus-within:border-jungleGreen h-[40px] `}
      >
        <input
          className="placeholder:text-14 bg-transparent focus:outline-none text-white lg:text-ebonyclay"
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={handleInputChange}
          disabled={disabled}
          onFocus={onFocus}
        />

        <Search className="fill-white lg:fill-ebonyclay" />
      </div>
      {optionList && data?.length > 0 && (
        <div className="rounded-sm border shadow-sm mt-3 max-h-400p w-full bg-white">
          <ul>
            {data.map((item) => (
              <li
                key={item.id}
                className="p-2 hover:bg-jungleGreen-10 cursor-pointer"
                onMouseDown={() => {
                  handleOptionSelection(item);
                }}
              >
                {item[label]}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export const SelectInput = ({
  label,
  errored,
  errorMessage,
  containerStyle = '',
  disabled,
  onChange,
  ...rest
}) => {
  return (
    <div className="relative mb-2">
      {label && (
        <label className="capitalize block text-16 mb-1 font-normal font-lato text-blueWood">
          {label}
        </label>
      )}
      <div>
        <div className={`${containerStyle}`}>
          <Select
            inputId={rest.name}
            styles={{
              ...customStyles,
              ...{
                control: (provided) => {
                  return {
                    ...provided,

                    borderRadius: '0.140rem',
                    border: errored
                      ? '0.5px solid #D43A42'
                      : '0.5px solid #bcc2cc',
                    '&:focus-within': {
                      borderColor: errored ? '#D43A42' : '#26C07F',
                    },
                    backgroundColor: 'transparent',
                    outline: 'none',
                    paddingRight: '.5rem',
                    height: '45px',
                    width: '100%',
                    boxShadow: 'none',
                  };
                },
                '&:focus-within': {
                  borderColor: 'rgba(0, 0, 0, 0) !important',
                },
                placeholder: (provided) => {
                  return { ...provided, color: '#BCC2CC', fontSize: '14px' };
                },
              },
            }}
            isSearchable={true}
            {...rest}
            components={{
              DropdownIndicator: rest.errored ? Info : ArrowDown,
            }}
            className={`bg-transparent text-black-01 focus:outline-none, ${
              disabled && 'text-uberblue'
            }`}
            isDisabled={disabled}
            onChange={onChange}
          />
        </div>
        <small
          className={`${
            errored ? ' relative -top-1 text-valencia truncate' : 'hidden'
          } `}
        >
          {errorMessage}
        </small>
      </div>
    </div>
  );
};

export const InputField = ({
  label,
  name,
  type,
  value = '',
  onChange,
  placeholder,
  errored,
  errorMsg,
  styles,
  disabled,
}) => (
  <>
    <div className="mb-2">
      <label className="text-blueWood">{label}</label>
    </div>
    <input
      type={type}
      name={name}
      placeholder={placeholder}
      className={`border-0.5 rounded-sm py-3 px-2 placeholder:text-14 focus-within:border-jungleGreen focus-within:border-0.5 focus:outline-none w-full border-loblolly disabled:bg-transparent  ${styles}  ${
        disabled && 'bg-white border-loblolly text-ebonyclay cursor-not-allowed'
      }`}
      onChange={onChange}
      value={value}
      disabled={disabled}
    />
    {errored && <div className="text-valencia text-xs mt-0.5">{errorMsg}</div>}
  </>
);

export const TextArea = ({
  name,
  description,
  value,
  onChange,
  errorMsg,
  errored,
  styles,
  disabled,
}) => (
  <>
    <textarea
      name={name}
      placeholder={description}
      className={`border-0.5 resize-none focus-within:border-jungleGreen focus-within:border-0.5 focus:outline-none p-2 w-full placeholder:text-14 border-loblolly ${styles}`}
      onChange={onChange}
      value={value}
      rows="4"
      disabled={disabled}
    />
    {errored && <div className="text-valencia text-xs mt-0.5">{errorMsg}</div>}
  </>
);
