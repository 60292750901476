import { createContext, useEffect, useReducer, useContext } from 'react';

import Loader from '../components/Loader';

import createAxiosInstance from '../utils/createAxiosInstance.js';
import { getToken } from '../utils/localStorage';
import userReducer from './reducers/user';

const initialState = {
  error: null,
  token: '',
  authenticating: true,
  loggedIn: false,
  user: null,
  isLoading: false,
};

const AuthContext = createContext();

const setLoading = (payload) => ({
  type: 'SET_LOADING',
  payload,
});

function AuthProvider(props) {
  const [state, dispatch] = useReducer(userReducer, initialState);

  useEffect(() => {
    const token = getToken();
    const userId = JSON.parse(localStorage.getItem('userId')) || '';
    if (!token) {
      return dispatch({ type: 'LOGGED_OUT' });
    }

    dispatch({
      type: 'LOGGED_IN',
      payload: {
        token,
        user: {
          id: userId,
        },
      },
    });
  }, []);

  if (state.authenticating) {
    return <Loader />;
  }

  const login = async (credentials, location, navigate) => {
    try {
      dispatch(setLoading(true));
      const { data } = await createAxiosInstance().post(
        'v1/login',
        credentials
      );

      localStorage.setItem('jwtToken', data.token);
      localStorage.setItem('userId', data.data.id);

      dispatch(setLoading(false));
      dispatch({
        type: 'LOGGED_IN',
        payload: {
          token: data.token,
          user: data.data,
        },
      });

      navigate(location.state?.from ? location.state.from : '/dashboard');
    } catch (error) {
      dispatch(setLoading(false));
      let err;
      if (error.response) {
        err = error.response.data.message;
      } else if (error.request) {
        err = 'Problem with request.';
      } else {
        err = error.message;
      }

      dispatch({
        type: 'SET_AUTH_ERROR',
        payload: { error: err },
      });
    }
  };

  const setUserData = (data) => {
    dispatch({
      type: 'SET_USER',
      payload: { ...data },
    });
  };

  const logout = () => {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('_expiredTime');
    dispatch({
      type: 'LOGGED_OUT',
    });
  };

  return (
    <AuthContext.Provider
      value={{ auth: state, dispatch, login, logout, setUserData }}
      {...props}
    />
  );
}

const useAuth = () => useContext(AuthContext);
export { AuthProvider, useAuth };
