import { useQuery, useMutation } from '@tanstack/react-query';
import { useAuth } from '../context/Auth';
import createAxiosInstance from '../utils/createAxiosInstance';

const fetch = async (axiosOptions = {}, signal) => {
  const { ...options } = axiosOptions;

  const axiosInstance = createAxiosInstance();
  const params = {
    ...options.params,
    limit: options.limit,
    offset: options.offset,
  };

  const { data } = await axiosInstance({
    method: 'GET',
    signal,
    ...options,
    params,
  });

  return data;
};

const appMutate = async (axiosOptions = {}, requestBody) => {
  const axiosInstance = createAxiosInstance();

  const { data } = await axiosInstance({
    method: 'POST',
    data: requestBody,
    ...axiosOptions,
  });

  return data;
};

export const useAppQuery = (
  queryKeyPrefix = '',
  axiosOptions,
  queryOptions = {}
) => {
  const { logout } = useAuth();

  return useQuery({
    queryKey: Array.isArray(queryKeyPrefix) ? queryKeyPrefix : [queryKeyPrefix],
    queryFn: async ({ signal }) => {
      try {
        const data = await fetch(axiosOptions, signal);
        return data;
      } catch (error) {
        if (error.response?.status === 401) {
          logout();
        }
        throw new Error(
          error.response?.data?.message || 'Process failed. Please refresh'
        );
      }
    },
    ...queryOptions,
  });
};

export const useAppMutation = (axiosOptions = {}, queryOptions = {}) => {
  const { logout } = useAuth();

  return useMutation({
    mutationFn: (requestBody) => appMutate(axiosOptions, requestBody),
    onError: (error) => {
      if (error.response?.status === 401) {
        logout();
      }
    },
    ...queryOptions,
  });
};
