import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';

import ModalHeader from './ModalHeader';
import { Input } from './Input';
import SearchIcon from './SearchIcon';
import { UnderlinedButton as Button } from './Button';
import { Toast } from '../helpers/toastify.helpers';

import { useAppQuery, useAppMutation } from '../hooks/useAppQuery';
import { useModal } from '../context/ModalContext';
import useDebounce from '../hooks/useDebounce';

const Row = ({ name, email, handleCheckboxChange, id, checked }) => (
  <div className="flex purge-table__row border-b border-tableBorder2 text-xs py-2">
    <div className="mr-4">
      <input
        className="bg-pampas"
        type="checkbox"
        onChange={(e) => handleCheckboxChange(e, id)}
        checked={checked}
      />
    </div>
    <p className="purge-table__name capitalize">{name}</p>
    <p className="purge-table__email">{email}</p>
  </div>
);

const Box = styled.div`
  .purge-table__name {
    flex: 1 1 48%;
    border: 1px;
  }
  .purge-table__email {
    flex: 1 1 48%;
  }
  .purge-table__row,
  .date-form {
    @media (min-width: 640px) {
      font-size: 14px;
    }
    @media (min-width: 1023px) {
      font-size: 16px;
    }
  }
`;

const PurgeInvestors = () => {
  const { handleModal } = useModal();
  const [purgedIDs, setPurgedIDs] = useState([]);
  const [checked, setChecked] = useState(false);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 1000);

  const { data: users } = useAppQuery(
    `all-users_${debouncedSearch}`,
    {
      url: `v1/users/search?q=${debouncedSearch}`,
    },
    {
      enabled: debouncedSearch?.length >= 3,
    }
  );

  const queryClient = useQueryClient();
  const { mutate, isPending } = useAppMutation(
    {
      url: 'v1/users/purge',
      data: { userIds: [...new Set(purgedIDs)] },
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({
          queryKey: [`all-users_${debouncedSearch}`],
          refetchType: 'all',
        });
        setChecked(false);
        handleModal();
        Toast({
          message: 'Purge Success!',
          type: 'success',
        });
      },
    }
  );

  useEffect(() => {
    if (users?.data?.length === 1) {
      setPurgedIDs([users?.data[0].id]);
    } else {
      setPurgedIDs([]);
    }
  }, [users]);

  const handleCheckboxChange = ({ target }, id) => {
    if (target.name === 'all_purge') {
      const allUserIds = users?.data?.map((user) => user.id) || [];
      setChecked(target.checked);
      setPurgedIDs(target.checked ? allUserIds : []);
    } else {
      setPurgedIDs((prevState) =>
        target.checked
          ? [...prevState, id]
          : prevState.filter((item) => item !== id)
      );
    }
  };

  const handlePurge = async () => {
    await mutate();
  };

  return (
    <Box className="w-11/12 my-0 mx-auto">
      <ModalHeader title="Purge Investors" />
      <p className="uppercase mb-8">proceed to delete inactive accounts</p>
      <div className="relative">
        <Input
          type="text"
          autoComplete="off"
          name="search"
          placeholder="Search for an Investor"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          required
        />
        <SearchIcon />
      </div>

      {users?.data?.length > 0 && (
        <div className="flex purge-table__row mt-8 mb-2 border-ebonyclay uppercase text-xs sm:text-14">
          {users.data.length > 1 && (
            <div className="block">
              <input
                checked={checked}
                type="checkbox"
                name="all_purge"
                onChange={handleCheckboxChange}
              />
            </div>
          )}
          <p className="ml-4 purge-table__name">Full name</p>
          <p className="purge-table__email">Email Address</p>
        </div>
      )}
      {users?.data.length > 0
        ? users?.data.map((investor) => (
            <Row
              key={investor?.id}
              id={investor?.id}
              name={`${investor?.fname} ${investor?.lname}`}
              email={investor?.email}
              handleCheckboxChange={handleCheckboxChange}
              checked={purgedIDs.some((id) => id === investor?.id)}
            />
          ))
        : null}

      {purgedIDs?.length > 0 && (
        <div className="flex justify-end mt-40p mb-4">
          <Button
            label="Cancel"
            styleClasses="block cursor-pointer mr-45"
            type="button"
            onClick={() => handleModal()}
          />
          <Button
            type="button"
            label="Submit"
            styleClasses="block cursor-pointer mr-6"
            onClick={handlePurge}
            disabled={!purgedIDs?.length}
            loading={isPending}
          />
        </div>
      )}
    </Box>
  );
};

export default PurgeInvestors;
