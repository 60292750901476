class IdleTimer {
  timeout: number;
  callback: () => void;
  timer: NodeJS.Timeout | undefined;
  active: boolean;
  events: (keyof WindowEventMap)[];

  constructor(timeout: string | number, callback: () => void) {
    this.timeout = Number(timeout) * 1000;
    this.callback = callback;

    this.timer = undefined;
    this.active = false;
    this.events = ['mousemove', 'keydown', 'scroll', 'click', 'touchstart'];

    this.cleanUp();
    this.init();
  }

  init() {
    this.events.forEach((event) => {
      window.addEventListener(event, this.onUserActive);
    });
    this.startTimer();
  }

  startTimer() {
    if (!this.active) {
      this.active = true;
      this.timer = setTimeout(this.onUserInactive, this.timeout);
    }
  }

  resetTimer() {
    clearTimeout(this.timer);
    this.active = false;
    this.startTimer();
  }

  onUserActive = () => {
    this.resetTimer();
  };

  onUserInactive = () => {
    this.active = false;
    this.cleanUp();
    this.callback();
  };

  cleanUp() {
    clearTimeout(this.timer);
    this.events.forEach((event) => {
      window.removeEventListener(event, this.onUserActive);
    });
  }
}

export default IdleTimer;
