const CloseButton = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.668 15.2528L24.9609 6.95989C25.3514 6.56936 25.9846 6.56936 26.3751 6.95989C26.7656 7.35041 26.7656 7.98357 26.3751 8.3741L18.0822 16.667L26.3751 24.9599C26.7656 25.3504 26.7656 25.9836 26.3751 26.3741C25.9846 26.7646 25.3514 26.7646 24.9609 26.3741L16.668 18.0812L8.37508 26.3741C7.98455 26.7646 7.35139 26.7646 6.96086 26.3741C6.57034 25.9836 6.57034 25.3504 6.96086 24.9599L15.2538 16.667L6.96086 8.3741C6.57034 7.98357 6.57034 7.35041 6.96086 6.95989C7.35139 6.56936 7.98455 6.56936 8.37508 6.95989L16.668 15.2528Z"
      fill="#283445"
    />
  </svg>
);

export const SmallCloseButton = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41L12.59 0Z"
      fill="#2E3A59"
    />
  </svg>
);

export default CloseButton;
