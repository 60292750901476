import Avatar from './Avatar';

/**
 * @returns ClientHeader component
 */
const ClientHeader = ({ user, fullname, onClick, nav }) => {
  return (
    <div
      className={`flex items-center md:justify-center xl:justify-start cursor-pointer mx-auto w-11/12 ${
        nav ? 'mt-3 sm:mt-14' : ''
      } text-white`}
    >
      <Avatar user={user} onClick={onClick} />
      <div
        className={` xl:flex ${
          nav ? 'flex lg:hidden' : 'hidden'
        } flex-col ml-10p`}
      >
        <span className="capitalize cursor-pointer text-14" onClick={onClick}>
          {fullname}
        </span>
        <span className="opacity-50 text-12">Super Admin</span>
      </div>
    </div>
  );
};

export default ClientHeader;
